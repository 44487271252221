import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { RHFTextField } from '../../../components/hook-form';
import axios from '../../../utils/axios';
import { ApiEndpoints } from '../../../config';
import { PATH_APP } from '../../../routes/paths';
import { FieldArray, Form, Formik } from 'formik';
import axiosInstance from '../../../utils/axios';

AddNewUnsubscriptionRules.propTypes = {
  isEdit: PropTypes.bool,
  currentUser: PropTypes.object,
};

export default function AddNewUnsubscriptionRules({
  isEdit,
  currentUser,
  toggleDrawer,
  getUnsubscriptionRulesList,
  webhookEndPoints,
}) {
  const navigate = useNavigate();
  const id = isEdit;
  const { enqueueSnackbar } = useSnackbar();
  const isEditCase = Boolean(isEdit);
  const [departmentChannels, setDepartmentChannels] = useState([]);
  const assignRuleTriggers=(data)=>{
    return data.map((rule)=>({
      ...rule,
      actionType:rule.action_type,
      endPoint:rule.end_point_id,
      autoText:rule.auto_text,
    }))
  }
  const initialValues = {
    ruleName: isEditCase ? isEdit?.name :'',
    ruleDescription: isEditCase ? isEdit?.description : '',
    condition: isEditCase ? isEdit?.condition : '',
    actions: isEditCase ? assignRuleTriggers(isEdit?.rule_triggers) : [
      {
        actionType: '',
        endPoint: '',
        autoText: '',
      },
    ],
    channels: isEditCase ? isEdit?.department_channels?.map((item) =>item.id)
    : [],
  };
  const validationSchema = Yup.object().shape({
    ruleName: Yup.string().required('Rule Name is required'),
    ruleDescription: Yup.string().required('Rule Description is required'),
    condition: Yup.string().required('Condition is required'),
    actions: Yup.array()
  .of(
    Yup.object().shape({
      actionType: Yup.string().nullable().required('Required'),
      endPoint: Yup.string().test(
        'end_point_required',
        'Required when actionType is "WELCOME"',
        (value, context) => {
          const { parent } = context;
          if (parent.actionType === 'WELCOME') {
            return !!value;
          }
          return true;
        }
      ),
      autoText: Yup.string().test(
        'AUto text required',
        'Required when actionType is "AUTO_TEXT"',
        (value, context) => {
          const { parent } = context;
          if (parent.actionType === 'AUTO_TEXT') {
            return !!value;
          }
          return true;
        }
      ),
    })
  )
  .required('Actions Required'),
    channels: Yup.array().min(1, 'Please select at least one option'),
  });

  const handleSubmit = async (data) => {
    console.log(data,'submitdata#')
    const payload = {
      condition: data.condition,
      departmentChannels: data.channels,
      description: data.ruleDescription,
      name: data.ruleName,
      ruleTriggers: data.actions,
    };
    const url =
      isEditCase && isEdit?.id ? ApiEndpoints.UNSUBSCRIPTION_RULES_LIST_UPDATE + '/' + isEdit?.id : ApiEndpoints.UNSUBSCRIPTION_RULES_LIST_NEW;
      const method =  isEditCase && isEdit?.id ? 'put' : 'post';
    await axios[method](url, payload)
      .then((response) => {
        if (response.data.status === 200) {
          enqueueSnackbar(response.data.message, { variant: 'success' });
          toggleDrawer();
          getUnsubscriptionRulesList();
        } else {
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
      })
      .catch((error) => {
        console.log(`error`, { error });
      });
  };
  const getDepartmentChannels = () => {
    axiosInstance
      .get(ApiEndpoints.DEPARTMENTS)
      .then((response) => {
        if (response.data.status === 200) {
          setDepartmentChannels(response?.data?.data?.departmentChannels);
        }
      })
      .catch((error) => {
        console.log(`error`, { error });
      });
  };
  useEffect(() => {
    getDepartmentChannels();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {({ values, errors, setFieldValue, setFieldTouched, setFieldError, setValues }) => {
        console.log(`formValuesSettings`, { values, errors });
        return (
          <Form>
            <Grid container width={500}>
              <Grid item md={12}>
                <Card sx={{ p: 3 }}>
                  <Box
                    sx={{
                      display: 'grid',
                      columnGap: 2,
                      rowGap: 3,
                      gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
                    }}
                  >
                    <Typography variant="h4">{!isEdit ? 'Add New Rule' : 'Update New Rule'}</Typography>
                    <TextField
                      name="ruleName"
                      label="Rule Name"
                      value={values.ruleName}
                      error={errors.ruleName}
                      helperText={errors.ruleName}
                      onChange={(e) => {
                        setFieldValue('ruleName', e.target.value);
                      }}
                    />
                    <TextField
                      name="ruleDescription"
                      label="Rule Description"
                      value={values.ruleDescription}
                      error={errors.ruleDescription}
                      helperText={errors.ruleDescription}
                      onChange={(e) => {
                        setFieldValue('ruleDescription', e.target.value);
                      }}
                    />
                    <TextField
                      name="condition"
                      label="Condition"
                      value={values.condition}
                      error={errors.condition}
                      helperText={errors.condition}
                      onChange={(e) => {
                        setFieldValue('condition', e.target.value);
                      }}
                    />
                    <FieldArray
                      name="actions"
                      render={(arrayHelpers) => (
                        <>
                          {values.actions.map((item, index) => (
                            <div style={{ display: 'block' }}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Select Rule</InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  name={`actions[${index}].actionType`}
                                  value={item.actionType}
                                  label="Select Rule"
                                  error={errors?.actions?.[index]?.actionType}
                                  onChange={(e) => {
                                    setFieldValue(`actions[${index}].actionType`, e.target.value);
                                    setFieldValue(`actions[${index}].autoText`, '');
                                    setFieldValue(`actions[${index}].endPoint`, '');
                                  }}
                                >
                                  <MenuItem value={'WEBHOOK'}>WEBHOOK</MenuItem>
                                  <MenuItem value={'AUTO_TEXT'}>AUTO_TEXT</MenuItem>
                                </Select>
                                <FormHelperText error={Boolean(errors?.actions?.[index]?.actionType)}>
                                  {errors?.actions?.[index]?.actionType}
                                </FormHelperText>
                              </FormControl>
                              {values?.actions?.[index]?.actionType == 'WEBHOOK' && (
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">Select Webhook</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name={`actions[${index}].endPoint`}
                                    value={item.endPoint}
                                    label="Select Webhook"
                                    error={errors?.actions?.[index]?.endPoint}
                                    onChange={(e) => setFieldValue(`actions[${index}].endPoint`, e.target.value)}
                                  >
                                    {webhookEndPoints?.map((item) => (
                                      <MenuItem value={item.id}>{item.name}</MenuItem>
                                    ))}
                                  </Select>
                                  <FormHelperText error={Boolean(errors?.actions?.[index]?.endPoint)}>
                                    {errors?.actions?.[index]?.endPoint}
                                  </FormHelperText>
                                </FormControl>
                              )}
                              {values?.actions?.[index]?.actionType == 'AUTO_TEXT' && (
                                <TextField
                                  name={`actions[${index}].autoText`}
                                  label="Auto Text"
                                  fullWidth
                                  value={values.actions?.[index]?.autoText}
                                  error={errors?.actions?.[index]?.autoText}
                                  helperText={errors?.actions?.[index]?.autoText}
                                  onChange={(e) => {
                                    setFieldValue(`actions[${index}].autoText`, e.target.value);
                                  }}
                                />
                              )}
                              <div style={{ display: 'flex', flexDirection: 'revert', gap: '5px', padding: '5px' }}>
                                {values.actions.length > 1 && (
                                  <LoadingButton
                                    type="button"
                                    variant="contained"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    -
                                  </LoadingButton>
                                )}
                                <LoadingButton
                                  type="button"
                                  variant="contained"
                                  onClick={() =>
                                    arrayHelpers.push({
                                      actionType: '',
                                      endPoint: '',
                                      autoText: '',
                                    })
                                  }
                                >
                                  +
                                </LoadingButton>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    />
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Select Channel</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        multiple
                        name="channels"
                        value={values.channels}
                        label="Select Webhook"
                        error={errors?.channels}
                        onChange={(e) => setFieldValue('channels', e.target.value)}
                      >
                        {departmentChannels?.map((channel) => (
                          <MenuItem value={channel.id}>{channel.name}</MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={Boolean(errors?.channels)}>{errors?.channels}</FormHelperText>
                    </FormControl>
                  </Box>

                  <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                    <LoadingButton type="submit" variant="contained">
                      {!isEdit ? 'Create Agent' : 'Save Changes'}
                    </LoadingButton>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}
