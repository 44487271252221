import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Card, Grid, Typography, MenuItem, TextField, Autocomplete } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFSelect, RHFTextField } from '../../../components/hook-form';
import axios from '../../../utils/axios';
import { ApiEndpoints } from '../../../config';
import { PATH_APP } from '../../../routes/paths';
function AddNewAuditor({ isEdit, currentUser, getAuditors, toggleDrawer, }) {
  const navigate = useNavigate();
  const id = isEdit;
  const isEditCase = Boolean(isEdit);
  const { enqueueSnackbar } = useSnackbar();
  const [departments, setDepartments] = useState([]);
  const [auditor, setAuditor] = useState();
  const newAuditorSchema = Yup.object().shape({
    auditorName: Yup.string().required('Auditor Name is required'),
    auditorDepartment: Yup.string().required('Department Name is required'),
    auditorEmail: Yup.string().email('Invalid email').required('Email is required'),
    auditorMobile: Yup.string().required('Mobile Number is required'),
    auditorPassword: Yup.string().when('isEdit', {
      is: false,
      then: Yup.string().required('Password is required'),
      otherwise: Yup.string().notRequired(),
    }),
  });
  const defaultValues = {
    auditorName: (isEditCase && auditor) ? isEdit?.name || auditor.name : '',
    auditorDepartment: (isEditCase && auditor) ? auditor?.agant_ids?.[0] : '',
    auditorEmail: (isEditCase && auditor) ? isEdit?.email || auditor.email : '',
    auditorMobile: (isEditCase && auditor) ? isEdit?.mobile_number || auditor.mobile_number : '',
}
  const methods = useForm({
    resolver: yupResolver(newAuditorSchema),
    defaultValues,
  });

  const StatusRole = ['ACTIVE', 'INACTIVE']
  const {
    reset,
    watch,
    control,
    enableReinitialize,
    handleSubmit,
    getValues,
    formState: { isSubmitting, errors },
  } = methods;
  const values = watch();

  const formValues = getValues();
  // useEffect(() => {
  //   if (isEdit) {
  //     reset(defaultValues);
  //   }
  //   if (!isEdit) {
  //     reset(defaultValues);
  //   }
  // }, [isEdit, auditor]);

  async function fetchAuditorDetails() {
    if (isEdit && id) {
      try {
        const response = await axios.get(`${ApiEndpoints.UPDATEAUDITOR}/${isEdit?.id}`);
        if(response.data.status === 200) {
        setAuditor(response.data.data.auditor);
        }
      } catch (error) {
        console.error('Failed to fetch auditor details:', error);
        enqueueSnackbar('Failed to fetch auditor details!', { variant: 'error' });
      }
    }
  }

  const getAuditorsDepartments = async () => {
    try {
      const response = await axios.get(ApiEndpoints.ADDAUDITOR);
      setDepartments(response?.data?.data);
    } catch (error) {
      console.error('Failed to fetch departments:', error);
      enqueueSnackbar('Failed to fetch !', { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchAuditorDetails();
    getAuditorsDepartments();
  }, [id, isEdit, enqueueSnackbar, methods]);

  useEffect(() => {
    if (isEdit) {
      reset(defaultValues);
    }
  }, [isEdit, auditor]);

  const onSubmit = async (data) => {
    const payload = {
      agant_ids: [data.auditorDepartment],
      id: 1,
      name: data.auditorName,
      email: data.auditorEmail,
      password: data.auditorPassword,
      mobile_number: data.auditorMobile,
    };
    let response;
    try {
      if (isEdit) {
         response = await axios.post(`${ApiEndpoints.UPDATEAUDITOR}/${isEdit?.id}`, payload);
        if(response?.data?.status === 200){
        enqueueSnackbar(response.data.message, { variant: 'success' });
        getAuditors();
        localStorage.removeItem('departmentAuditorDetails');
        toggleDrawer();
        }else{
        enqueueSnackbar(response.data.message, { variant: 'error' });
        }
      } else {
        const response = await axios.post(ApiEndpoints.ADDAUDITOR, payload);
        enqueueSnackbar(response.data.message, { variant: 'success' });
        getAuditors();
        localStorage.removeItem('departmentAuditorDetails');
        toggleDrawer();
      }
    } catch (error) {
      enqueueSnackbar(`Failed to ${isEdit ? 'update' : 'add'} auditor!`, { variant: 'error' });
      console.error('Error:', error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container width={500}>
        <Grid item md={12}>
          <Card sx={{ p: 3 }}>
            <Typography variant="h4">{!isEdit ? 'Add New Auditor' : 'Save Changes'}</Typography>
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                marginTop: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
              }}
            >
              <RHFTextField name="auditorName" label="Auditor Name" />
              <Autocomplete
                multiple
                id="tags-outlined"
                options={departments}
                getOptionLabel={(option) => option.email}
                filterSelectedOptions
                sx={{ maxWidth: { md: '100%' } }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Agents"
                  />
                )}
              />
              <RHFTextField name="auditorMobile" label="Mobile Number" />
              <RHFTextField name="auditorEmail" label="Auditor Email" disabled={isEdit} />
              {!isEdit && <RHFTextField name="auditorPassword" label="Password" type="password" />}
              <Autocomplete
               id="tags-outlined"
               options={StatusRole}
               sx={{ maxWidth: { md: '100%' } }}
               renderInput={(params) => (
                 <TextField
                   {...params}
                   label="Status"
                 />
               )}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={methods.formState.isSubmitting}>
                {!isEdit ? 'Create Auditor' : 'Save Changes'}
              </LoadingButton>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}

AddNewAuditor.propTypes = {
  isEdit: PropTypes.bool,
  currentUser: PropTypes.object,
};

export default AddNewAuditor;