import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { RHFTextField } from '../../../components/hook-form';
import axios from '../../../utils/axios';
import { ApiEndpoints } from '../../../config';
import { PATH_APP } from '../../../routes/paths';
import { FieldArray, Form, Formik } from 'formik';
import axiosInstance from '../../../utils/axios';

AddNewMessageRoutingConfig.propTypes = {
  isEdit: PropTypes.bool,
  currentUser: PropTypes.object,
};

export default function AddNewMessageRoutingConfig({ isEdit, toggleDrawer, getUnsubscriptionRulesList }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const isEditCase = Boolean(isEdit);
  const [dropdownData, setDropdownData] = useState();
  const initialValues = {
    ruleName: isEditCase ? isEdit?.name : '',
    ruleDescription: isEditCase ? isEdit?.description : '',
    channels: isEditCase ? isEdit?.department_channels?.map((item) => item.id) : [],
    apiUrl: isEditCase ? isEdit?.end_point?.id : '',
    defaultDepartmentRouting: isEditCase ? isEdit?.department?.id : '',
  };
  const validationSchema = Yup.object().shape({
    ruleName: Yup.string().required('Rule Name is required'),
    ruleDescription: Yup.string().required('Rule Description is required'),
    channels: Yup.array().min(1, 'Please select at least one option'),
    apiUrl: Yup.string().required('Required Field'),
    defaultDepartmentRouting: Yup.string().required('Required Field'),
  });

  const handleSubmit = (data) => {
    const payload = {
      default_department_id: data.defaultDepartmentRouting,
      department_channels: data.channels,
      description: data.ruleDescription,
      end_point_id: data.apiUrl,
      name: data.ruleName,
    };
    const url =
      isEditCase && isEdit?.id
        ? ApiEndpoints.MESSAGE_ROUTING_CONFIGURATION_UPDATE + '/' + isEdit?.id
        : ApiEndpoints.MESSAGE_ROUTING_CONFIGURATION_NEW;
    axios
      .post(url, payload)
      .then((response) => {
        if (response.data.status === 200) {
          enqueueSnackbar(response.data.message, { variant: 'success' });
          toggleDrawer();
          getUnsubscriptionRulesList();
        } else {
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
      })
      .catch((error) => {
        console.log(`error`, { error });
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };
  const getDropDownData = () => {
    axiosInstance
      .get(ApiEndpoints.MESSAGE_ROUTING_CONFIGURATION_NEW)
      .then((response) => {
        setDropdownData(response?.data?.data);
      })
      .catch((error) => {
        console.log(`error`, { error });
      });
  };
  useEffect(() => {
    getDropDownData();
  }, []);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
      validateOnMount={false}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {({ values, errors, setFieldValue, setFieldTouched, setFieldError, setValues }) => {
        console.log(`formValuesSettings`, { values, errors });
        return (
          <Form>
            <Grid container width={500}>
              <Grid item md={12}>
                <Card sx={{ p: 3 }}>
                  <Box
                    sx={{
                      display: 'grid',
                      columnGap: 2,
                      rowGap: 3,
                      gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
                    }}
                  >
                    <Typography variant="h4">{!isEdit ? 'Add New Rule' : 'Update New Rule'}</Typography>
                    <TextField
                      name="ruleName"
                      label="Rule Name"
                      value={values.ruleName}
                      error={errors.ruleName}
                      helperText={errors.ruleName}
                      onChange={(e) => {
                        setFieldValue('ruleName', e.target.value);
                      }}
                    />
                    <TextField
                      name="ruleDescription"
                      label="Rule Description"
                      value={values.ruleDescription}
                      error={errors.ruleDescription}
                      helperText={errors.ruleDescription}
                      onChange={(e) => {
                        setFieldValue('ruleDescription', e.target.value);
                      }}
                    />
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Select Channel</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        multiple
                        name="channels"
                        value={values.channels}
                        label="Select Channel"
                        error={errors?.channels}
                        onChange={(e) => setFieldValue('channels', e.target.value)}
                      >
                        {dropdownData?.departmentChannels?.map((item) => (
                          <MenuItem value={item?.id}>{item?.name}</MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={Boolean(errors?.channels)}>{errors?.channels}</FormHelperText>
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Select Api URL</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="apiUrl"
                        value={values.apiUrl}
                        label="Select Api URL"
                        error={errors?.apiUrl}
                        onChange={(e) => setFieldValue('apiUrl', e.target.value)}
                      >
                        {dropdownData?.businessEndPoint?.map((item) => (
                          <MenuItem value={item?.id}>{item?.name}</MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={Boolean(errors?.apiUrl)}>{errors?.apiUrl}</FormHelperText>
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Select Default Department Routing</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="defaultDepartmentRouting"
                        value={values.defaultDepartmentRouting}
                        label="Select Default Department Routing"
                        error={errors?.defaultDepartmentRouting}
                        onChange={(e) => setFieldValue('defaultDepartmentRouting', e.target.value)}
                      >
                        {dropdownData?.departments?.map((item) => (
                          <MenuItem value={item?.id}>{item?.name}</MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={Boolean(errors?.defaultDepartmentRouting)}>
                        {errors?.defaultDepartmentRouting}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                    <LoadingButton type="submit" variant="contained">
                      {!isEdit ? 'Create Agent' : 'Save Changes'}
                    </LoadingButton>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}
