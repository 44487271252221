import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { TableRow, TableCell, Typography, MenuItem } from '@mui/material';
// components
import Label from '../../../../components/Label';
import Iconify from '../../../../components/Iconify';
import { TableMoreMenu } from '../../../../components/table';
import CustomConfirmBox from 'src/components/CustomConfirmBox';

// ----------------------------------------------------------------------

AgentTableRow.propTypes = {
    row: PropTypes.object,
    selected: PropTypes.bool,
    onEditRow: PropTypes.func,
    onSelectRow: PropTypes.func,
    onDeleteRow: PropTypes.func,
};

export default function AgentTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow }) {
    const theme = useTheme();

    const { id, name, department, mobile_number, email, status } = row;

    const [openMenu, setOpenMenuActions] = useState(null);
    const [openDialogBox, setOpenDialogBox] = useState(false);

    const handDialogBox = () => {
      setOpenDialogBox(!openDialogBox);
    };

    const handleDialogBoxYes = () => {
      onDeleteRow();
    };

    const handleOpenMenu = (event) => {
        setOpenMenuActions(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    return (
        <>
        <CustomConfirmBox
        open={openDialogBox}
        handleClose={handDialogBox}
        title={'Are you sure you want to delete ?'}
        handleCloseYes={handleDialogBoxYes}
      />
        <TableRow hover selected={selected}>
            <TableCell align="center">
                <Typography variant="subtitle2" noWrap>
                    {id}
                </Typography>
            </TableCell>

            <TableCell align="center">{name}</TableCell>

            {/* <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
                {name}
            </TableCell> */}

            {/* <TableCell align="center">
                {department}
            </TableCell> */}

            <TableCell align="center">
                {mobile_number}
            </TableCell>
            <TableCell align="center">
                {email}
            </TableCell>
            {/* <TableCell align="left">
                {status}
            </TableCell> */}

            <TableCell align="center">
                <TableMoreMenu
                    open={openMenu}
                    onOpen={handleOpenMenu}
                    onClose={handleCloseMenu}
                    actions={
                        <>
                            <MenuItem
                                onClick={() => {
                                    handDialogBox();
                                    handleCloseMenu();
                                  }}
                                sx={{ color: 'error.main' }}
                            >
                                <Iconify icon={'eva:trash-2-outline'} />
                                Delete
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    onEditRow();
                                    handleCloseMenu();
                                }}
                            >
                                <Iconify icon={'eva:edit-fill'} />
                                Edit
                            </MenuItem>
                        </>
                    }
                />
            </TableCell>
        </TableRow>
        </>
    );
}
