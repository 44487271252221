import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Card, Grid, Stack, Typography, MenuItem, InputAdornment, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField, RHFSelect, RHFRadioGroup } from '../../../components/hook-form';
import axios from '../../../utils/axios';
import { ApiEndpoints } from '../../../config';
import { PATH_APP } from '../../../routes/paths';
import Iconify from 'src/components/Iconify';
import { LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';

AddNewAgent.propTypes = {
  isEdit: PropTypes.bool,
  currentUser: PropTypes.object,
};

export default function AddNewAgent({ isEdit, currentUser, toggleDrawer, getAgents, currentPage, rowsPerPage }) {
  const navigate = useNavigate();
  const id = isEdit;
  const { enqueueSnackbar } = useSnackbar();
  const isEditCase = Boolean(isEdit);
  const [departments, setDepartments] = useState([]);
  const [agentData, setAgentData] = useState();
  const [showPassword, setShowPassword] = useState({ password: false, confirmPassword: false });

  const defaultValues = useMemo(
    () => ({
      agentName: isEditCase && agentData ? agentData.name : '',
      agentDepartment:
        (isEditCase && agentData)
          ? agentData?.organisation_department_id : '',
      agentEmail: isEditCase && agentData ? agentData.email : '',
      agentMobile: isEditCase && agentData ? agentData.mobile_number : '',
      password: '',
      confirmPassword: '',
      startTime: dayjs(),
      endTime: dayjs(),
      days:''
    }),
    [agentData]
  );
  const newAgentSchema = Yup.object().shape({
    agentName: Yup.string().required('Agent Name is required'),
    // agentDepartment: Yup.string().required('Department Name is required'),
    agentEmail: Yup.string().email('Invalid email').required('Email is required'),
    agentMobile: Yup.string()
      .matches(/^[0-9]{10}$/, 'Mobile number must be a 10-digit number')
      .required('Mobile number is required'),
    password: isEditCase ? Yup.string().notRequired() : Yup.string()
      .min(4, 'Password must be at least 4 characters')
      .max(50, 'Password must not exceed 50 characters')
      .required('Password is required'),
    confirmPassword: isEditCase ? Yup.string().notRequired() : Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  });
  const methods = useForm({
    resolver: yupResolver(newAgentSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    enableReinitialize,
    handleSubmit,
    getValues,
    setValue,
    formState: { isSubmitting, errors },
  } = methods;
  const values = watch();

  const formValues = getValues();
  console.log("form_values_addAgent",formValues)
  useEffect(() => {
    if (isEdit) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, agentData]);

  const getAgentsDepartments = async () => {
    try {
      const response = await axios.get(ApiEndpoints.DEPARTMENTS);
      const departments = response.data.data;
      setDepartments(departments);
    } catch (error) {
      console.error('Failed to fetch departments:', error);
      enqueueSnackbar('Failed to fetch departments!', { variant: 'error' });
    }
  };

  const fetchAgentDetails = async () => {
    if (isEdit && id) {
      try {
        const response = await axios.get(`${ApiEndpoints.GETAGENT}/${id}`);
        if (response.data.status === 200) {
          setAgentData(response.data.data);
        }
      } catch (error) {
        console.error('Failed to fetch agent details:', error);
        enqueueSnackbar('Failed to fetch agent details!', { variant: 'error' });
      }
    }
  };

  useEffect(() => {
    fetchAgentDetails();
    getAgentsDepartments();
  }, []);

  const onSubmit = async (data) => {
    try {
      let response;
      const payload = {
        // department_ids: [data.agentDepartment],
        name: data.agentName,
        // departmentName: data.agentDepartment,
        email: data.agentEmail,
        mobile_number: data.agentMobile,
        password: data.password,
        organisation_department_id: data.agentDepartment,
        start_time: moment(data.startTime).format('hh:mm:ss'),
        end_time: moment(data.endTime).format('hh:mm:ss'), 
        weekdays: data.days == 1 ? true : false,
        weekends: data.days == 2 ? true : false,
      }
      if (isEdit) {
         response = await axios.post(`${ApiEndpoints.UPDATEAGENT}/${id}`, payload);
        if (response?.data?.status === 200){
          enqueueSnackbar(response.data.message, { variant: 'success' });
          getAgents();
          localStorage.removeItem('departmentAgentDetails');
          toggleDrawer();
        }else{
          enqueueSnackbar(response?.data?.message, { variant: 'error' });
        }
      } else {
        response = await axios.post(ApiEndpoints.ADDAGENT, payload);
      if(response?.data?.status === 200){
        enqueueSnackbar(response.data.message, { variant: 'success' });
        getAgents();
        localStorage.removeItem('departmentAgentDetails');
        toggleDrawer();
      }else{
        enqueueSnackbar(response?.data?.message, { variant: 'error' });
      }
    }
    } catch (error) {
      enqueueSnackbar(`Failed to ${isEdit ? 'update' : 'add'} agent!`, { variant: 'error' });
      console.error('Error:', error);
    }
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container width={500}>
        <Grid item md={12}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
              }}
            >
              <Typography variant="h4">{!isEdit ? 'Add New Agent' : 'Save Changes'}</Typography>
              <RHFTextField name="agentName" label="Agent Name" />
              <RHFSelect
                name="agentDepartment"
                label="Department"
                size="large"
                InputLabelProps={{ shrink: true }}
                value={formValues?.agentDepartment}
                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                sx={{ maxWidth: { md: '100%' } }}
                disabled={localStorage.getItem('departmentAgentDetails')}
              >
                {departments?.map((dept, i) => (
                  <MenuItem
                    key={dept.id}
                    value={dept.id}
                    sx={{ mx: 1, my: 0.5, borderRadius: 0.75, typography: 'body2', textTransform: 'capitalize' }}
                  >
                    {dept.name}
                  </MenuItem>
                ))}
              </RHFSelect>
              <RHFTextField name="agentEmail" label="Agent Email" disabled={isEditCase} />
              <RHFTextField type="number" name="agentMobile" label="Agent Mobile No" />
              {!isEditCase &&<><RHFTextField
                name="password"
                autoComplete="new-password"
                label="Password"
                type={showPassword.password ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword({ ...showPassword, password: !showPassword.password })}
                        edge="end"
                      >
                        <Iconify icon={showPassword.password ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <RHFTextField
                name="confirmPassword"
                label="Confirm Password"
                type={showPassword.confirmPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowPassword({ ...showPassword, confirmPassword: !showPassword.confirmPassword })
                        }
                        edge="end"
                      >
                        <Iconify icon={showPassword.confirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              </>}
              <RHFRadioGroup name={'days'} options={['1', '2']} getOptionLabel={['WeekDays', 'WeekEnds']} row={true} />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileTimePicker name='startTime' onChange={(e)=>setValue('startTime',e)} value={formValues?.startTime}/>
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileTimePicker name='endTime' onChange={(e)=>setValue('endTime',e)} value={formValues?.endTime}/>
              </LocalizationProvider>
            </Box>
            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {!isEdit ? 'Create Agent' : 'Save Changes'}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
