import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { RHFTextField } from '../../../components/hook-form';
import axios from '../../../utils/axios';
import { ApiEndpoints } from '../../../config';
import { PATH_APP } from '../../../routes/paths';
import { FieldArray, Form, Formik } from 'formik';
import axiosInstance from '../../../utils/axios';

AddNewDataExtractionRules.propTypes = {
  isEdit: PropTypes.bool,
};

export default function AddNewDataExtractionRules({ isEdit, getUnsubscriptionRulesList, toggleDrawer }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const isEditCase = Boolean(isEdit);
  const [formData, setFormData] = useState();
  
 
  const initialValues = {
    ruleName: isEditCase && formData ? formData?.name : '',
    ruleDescription: isEditCase && formData ? formData?.description : '',
    condition: isEditCase && formData ? formData?.type : '',
    actions:
      isEditCase && formData
        ? formData?.rule_triggers
        : [
            {
              action_type: '',
              end_point: 0,
              text: '',
              email_ids: [],
            },
          ],
    channels: isEditCase && formData ? formData?.department_channels : [],
    conditionTextRegex: isEditCase && formData ? formData?.regex : '',
    conditionTextJavascript: isEditCase && formData ? formData?.condition : '',
    keyName: isEditCase && formData ? formData?.key : '',
  };
  const validationSchema = Yup.object().shape({
    ruleName: Yup.string().required('Rule Name is required'),
    ruleDescription: Yup.string().required('Rule Description is required'),
    condition: Yup.string().required('Condition is required'),
    // conditionTextRegex: Yup.string().required('Condition Text is required'),
    actions: Yup.array()
      .of(
        Yup.object().shape({
          action_type: Yup.string().nullable().required('Required'),
          end_point: Yup.string().test(
            'end_point_required',
            'Required when action_type is "WELCOME"',
            (value, context) => {
              const { parent } = context;
              if (parent.action_type === 'WELCOME') {
                return !!value;
              }
              return true;
            }
          ).nullable(),
          text: Yup.string().test(
            'Auto text required',
            'Required when action_type is "SEND_ALERT"',
            (value, context) => {
              const { parent } = context;
              if (parent.action_type === 'SEND_ALERT') {
                return !!value;
              }
              return true;
            }
          ),
          // email_ids: Yup.string()
          //   .email('Invalid Email')
          //   .test('Email required', 'Required when action_type is "SEND_ALERT"', (value, context) => {
          //     const { parent } = context;
          //     if (parent.action_type === 'SEND_ALERT') {
          //       return !!value;
          //     }
          //     return true;
          //   }),
          email_ids: Yup.array()
            .of(Yup.string().email('Invalid Email').required("Please Enter Contact Person's Name"))
            .min(1, "At least one Contact Person's Name is Required")
            .test('Email required', 'Required when action_type is "SEND_ALERT"', (value, context) => {
              const { parent } = context;
              if (parent.action_type === 'SEND_ALERT') {
                return !!value;
              }
              return true;
            }),
        })
      )
      .required('Actions Required'),
    channels: Yup.array().min(1, 'Please select at least one option'),
    keyName: Yup.string().required('Key name is required'),
  });
  const transformData = (data) => {
    return data?.map((item) => ({
      action_type: item.action_type,
      end_point: item.end_point,
      text: item.text,
      email_ids: item.email_ids ? item.email_ids : [],
    }));
  };
  const handleSubmit = (data) => {
    const payload = {
      condition: data.conditionTextJavascript,
      department_channels: data.channels,
      description: data.ruleDescription,
      key: data.keyName,
      name: data.ruleName,
      regex: data.conditionTextRegex,
      rule_triggers: transformData(data.actions),
      type: data.condition,
    };
    const url =
      isEditCase && isEdit && formData
        ? ApiEndpoints.DATA_EXTRACTION_RULES_UPDATE + '/' + formData?.id
        : ApiEndpoints.DATA_EXTRACTION_RULES_NEW;
    axios
      .post(url, payload)
      .then((response) => {
        if (response.data.status === 200) {
          enqueueSnackbar(response.data.message, { variant: 'success' });
          toggleDrawer();
          getUnsubscriptionRulesList();
        } else {
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
      })
      .catch((error) => {
        console.log(`error`, { error });
      });
  };
  const [dropDownData, setDropDownData] = useState();
  const getDropdownData = () => {
    axios
      .get( ApiEndpoints.DATA_EXTRACTION_RULES_NEW)
      .then((response) => {
        if (response.data.status === 200) {
          setDropDownData(response.data.data);
        } else {
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
      })
      .catch((error) => {
        console.log(`error`, { error });
      });
  };
  useEffect(() => {
    getDropdownData();
    if (isEditCase && isEdit?.id) {
      getFormDataById();
    }
  }, []);
  const getFormDataById = () => {
    axios
      .get(`${ApiEndpoints.DATA_EXTRACTION_RULES_UPDATE}/${isEdit?.id}`)
      .then((response) => {
        if (response.data.status === 200) {
          setFormData(response?.data?.data?.dataExtractionRuleForm);
        } else {
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
      })
      .catch((error) => {
        console.log(`error`, { error });
      });
  };
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={true}
      validateOnMount={false}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {({ values, errors, setFieldValue, setFieldTouched, setFieldError, setValues }) => {
        return (
          <Form>
            <Grid container width={500}>
              <Grid item md={12}>
                <Card sx={{ p: 3 }}>
                  <Box
                    sx={{
                      display: 'grid',
                      columnGap: 2,
                      rowGap: 3,
                      gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
                    }}
                  >
                    <Typography variant="h4">{!isEdit ? 'Add New Rule' : 'Update New Rule'}</Typography>
                    <TextField
                      name="ruleName"
                      label="Rule Name"
                      value={values.ruleName}
                      error={errors.ruleName}
                      helperText={errors.ruleName}
                      onChange={(e) => {
                        setFieldValue('ruleName', e.target.value);
                      }}
                    />
                    <TextField
                      name="ruleDescription"
                      label="Rule Description"
                      value={values.ruleDescription}
                      error={errors.ruleDescription}
                      helperText={errors.ruleDescription}
                      onChange={(e) => {
                        setFieldValue('ruleDescription', e.target.value);
                      }}
                    />
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Select Condition</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="condition"
                        value={values.condition}
                        label="Select Condition"
                        error={errors?.condition}
                        onChange={(e) => setFieldValue('condition', e.target.value)}
                      >
                        {dropDownData?.dataExtractorType?.map((item) => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={Boolean(errors?.condition)}>{errors?.condition}</FormHelperText>
                    </FormControl>
                    {values.condition === 'REGEX' && (
                      <TextField
                        name="conditionTextRegex"
                        label="Regex"
                      value={values.conditionTextRegex}
                        error={errors.conditionTextRegex}
                        helperText={errors.conditionTextRegex}
                        onChange={(e) => {
                          setFieldValue('conditionTextRegex', e.target.value);
                        }}
                      />
                    )}
                    {values.condition === 'JAVASCRIPT' && (
                      <TextField
                        name="conditionTextJavascript"
                        label="Javascript"
                      value={values.conditionTextJavascript}
                        error={errors.conditionTextJavascript}
                        helperText={errors.conditionTextJavascript}
                        onChange={(e) => {
                          setFieldValue('conditionTextJavascript', e.target.value);
                        }}
                      />
                    )}
                    <TextField
                      name="keyName"
                      label="Key Name(Extracted data will be sent as value to this key name)"
                      value={values.keyName}
                      error={errors.keyName}
                      helperText={errors.keyName}
                      onChange={(e) => {
                        setFieldValue('keyName', e.target.value);
                      }}
                    />
                    <label htmlFor="actions">Perform These Actions</label>
                    <FieldArray
                      name="actions"
                      render={(arrayHelpers) => (
                        <>
                          {values.actions.map((item, index) => (
                            <div style={{ display: 'block' }}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Select Rule</InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  name={`actions[${index}].action_type`}
                                  value={item.action_type}
                                  label="Select Rule"
                                  error={errors?.actions?.[index]?.action_type}
                                  onChange={(e) => {
                                    setFieldValue(`actions[${index}].action_type`, e.target.value);
                                    setFieldValue(`actions[${index}].text`, '');
                                    setFieldValue(`actions[${index}].end_point`, '');
                                    setFieldValue(`actions[${index}].email_ids`, '');
                                  }}
                                >
                                  {dropDownData?.dataExtractionTriggerActionTypes?.map((item) => (
                                    <MenuItem value={item}>{item}</MenuItem>
                                  ))}
                                </Select>
                                <FormHelperText error={Boolean(errors?.actions?.[index]?.action_type)}>
                                  {errors?.actions?.[index]?.action_type}
                                </FormHelperText>
                              </FormControl>
                              {values?.actions?.[index]?.action_type == 'WEBHOOK' && (
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">Select Webhook</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name={`actions[${index}].end_point`}
                                    value={item.end_point !== '' ? item.end_point : 0}
                                    label="Select Webhook"
                                    error={errors?.actions?.[index]?.end_point}
                                    onChange={(e) => setFieldValue(`actions[${index}].end_point`, e.target.value)}
                                  >
                                    {dropDownData?.businessEndPoints?.map((item) => (
                                      <MenuItem value={item.id}>{item.name}</MenuItem>
                                    ))}
                                  </Select>
                                  <FormHelperText error={Boolean(errors?.actions?.[index]?.end_point)}>
                                    {errors?.actions?.[index]?.end_point}
                                  </FormHelperText>
                                </FormControl>
                              )}
                              {values?.actions?.[index]?.action_type == 'SEND_ALERT' && (
                                <div style={{ display: 'grid', gap: '10px' }}>
                                  <TextField
                                    name={`actions[${index}].email_ids`}
                                    label="To Email"
                                    fullWidth
                                    value={values?.actions?.[index]?.email_ids}
                                    error={errors?.actions?.[index]?.email_ids}
                                    helperText={errors?.actions?.[index]?.email_ids}
                                    onChange={(e) => {
                                      setFieldValue(`actions[${index}].email_ids`, (e.target.value || '').split(','));
                                    }}
                                  />
                                  <TextField
                                    name={`actions[${index}].text`}
                                    label="Enter Text"
                                    fullWidth
                                    value={values.actions?.[index]?.text}
                                    error={errors?.actions?.[index]?.text}
                                    helperText={errors?.actions?.[index]?.text}
                                    onChange={(e) => {
                                      setFieldValue(`actions[${index}].text`, e.target.value);
                                    }}
                                  />
                                </div>
                              )}
                              <div style={{ display: 'flex', flexDirection: 'revert', gap: '5px', padding: '5px' }}>
                                {values.actions.length > 1 && (
                                  <LoadingButton
                                    type="button"
                                    variant="contained"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    -
                                  </LoadingButton>
                                )}
                                <LoadingButton
                                  type="button"
                                  variant="contained"
                                  onClick={() =>
                                    arrayHelpers.push({
                                      action_type: '',
                                      end_point: '',
                                      text: '',
                                    })
                                  }
                                >
                                  +
                                </LoadingButton>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    />
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Select Channel</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        multiple
                        name="channels"
                        value={values.channels}
                        label="Select Webhook"
                        error={errors?.channels}
                        onChange={(e) => setFieldValue('channels', e.target.value)}
                      >
                        {dropDownData?.departmentChannels?.map((item) => (
                          <MenuItem value={item.id}>{item.name}</MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={Boolean(errors?.channels)}>{errors?.channels}</FormHelperText>
                    </FormControl>
                  </Box>

                  <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                    <LoadingButton type="submit" variant="contained">
                      {!isEdit ? 'Create Agent' : 'Save Changes'}
                    </LoadingButton>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}
