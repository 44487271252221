import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { RHFTextField } from '../../../components/hook-form';
import axios from '../../../utils/axios';
import { ApiEndpoints } from '../../../config';
import { PATH_APP } from '../../../routes/paths';
import { FieldArray, Form, Formik } from 'formik';
import axiosInstance from '../../../utils/axios';
import { values } from 'lodash';

AddNewAutoReplyRules.propTypes = {
  isEdit: PropTypes.bool,
};

export default function AddNewAutoReplyRules({ isEdit, toggleDrawer, getUnsubscriptionRulesList, webhookEndPoints }) {
  const navigate = useNavigate();
  const id = isEdit;
  const { enqueueSnackbar } = useSnackbar();
  const isEditcase = Boolean(isEdit);
  const [dropdownData, setDropdownData] = useState();
  const initialValues = {
    ruleName: isEditcase ? isEdit?.name : '',
    ruleDescription: isEditcase ? isEdit?.description : '',
    typeOfReply: isEditcase ? isEdit?.type : '',
    replyMessage: isEditcase ? isEdit?.body : '',
    channels: isEditcase ? isEdit?.department_channels?.map((item) => item.id) : [],
    recipients: isEditcase ? isEdit?.recipients_selection_criteria : '',
    // keyName: isEditcase? isEdit?.body : '',
    selectApi: isEditcase ? [isEdit?.selected_business_end_point_id] : [],
    delayType: isEditcase ? (isEdit?.delay_after_arrival_in_mins !== null ? 'delay_timer' : 'Always send') : '',
    delayTime: isEditcase ? isEdit?.delay_after_arrival_in_mins : '',
  };
  const validationSchema = Yup.object().shape({
    // ruleName: Yup.string().required('Rule Name is required'),
    // ruleDescription: Yup.string().required('Rule Description is required'),
    // typeOfReply: Yup.string().required('Condition is required'),
    // replyMessage: Yup.string().required('Condition Text is required'),
    // channels: Yup.array().min(1, 'Please select at least one option'),
    // keyName: Yup.string().required('Key name is required'),
    // recipients: Yup.string().required('Required Field'),
    // selectApi: Yup.string().required('Required Field'),
    // delayType: Yup.string().required('Required Field'),
  });
  const getDropDownData = () => {
    axiosInstance
      .get(ApiEndpoints.AUTO_REPLY_NEW)
      .then((response) => {
        if (response.data.status === 200) {
          setDropdownData(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(`error`, { error });
      });
  };
  const handleSubmit = (data) => {
    const payload = {
      body: data.replyMessage,
      delay_after_arrival_in_mins: data.delayTime,
      department_channels: data.channels,
      description: data.ruleDescription,
      name: data.ruleName,
      recipients_selection_criteria: data.recipients,
      // "rule_customer_numbers": data.keyName,
      selected_business_end_point_ids: data.selectApi,
      type: data.typeOfReply,
    };
    const url =
      isEditcase && isEdit?.id ? ApiEndpoints.AUTO_REPLY_UPDATE + '/' + isEdit?.id : ApiEndpoints.AUTO_REPLY_NEW;
    axios
      .post(url, payload)
      .then((response) => {
        if (response.data.status === 200) {
          enqueueSnackbar(response.data.message, { variant: 'success' });
          toggleDrawer();
          getUnsubscriptionRulesList();
        } else {
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
      })
      .catch((error) => {
        console.log(`error`, { error });
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };
  useEffect(() => {
    getDropDownData();
  }, []);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      enableReinitialize
      validateOnBlur={false}
      validateOnMount={false}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {({ values, errors, setFieldValue, setFieldTouched, setFieldError, setValues }) => {
        console.log(`formValuesSettings`, { values, errors });
        return (
          <Form>
            <Grid container width={500}>
              <Grid item md={12}>
                <Card sx={{ p: 3 }}>
                  <Box
                    sx={{
                      display: 'grid',
                      columnGap: 2,
                      rowGap: 3,
                      gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
                    }}
                  >
                    <Typography variant="h4">{!isEdit ? 'Add New Rule' : 'Update New Rule'}</Typography>
                    <TextField
                      name="ruleName"
                      label="Rule Name"
                      value={values.ruleName}
                      error={errors.ruleName}
                      helperText={errors.ruleName}
                      onChange={(e) => {
                        setFieldValue('ruleName', e.target.value);
                      }}
                    />
                    <TextField
                      name="ruleDescription"
                      label="Rule Description"
                      value={values.ruleDescription}
                      error={errors.ruleDescription}
                      helperText={errors.ruleDescription}
                      onChange={(e) => {
                        setFieldValue('ruleDescription', e.target.value);
                      }}
                    />
                    <Divider />
                    <div style={{ display: 'grid', width: '100%' }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select Type Of Reply</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="typeOfReply"
                          value={values.typeOfReply}
                          label="Select Type Of Reply"
                          error={errors?.typeOfReply}
                          onChange={(e) => setFieldValue('typeOfReply', e.target.value)}
                        >
                          {dropdownData?.autoReplyMessageTypes?.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </Select>
                        <FormHelperText error={Boolean(errors?.typeOfReply)}>{errors?.typeOfReply}</FormHelperText>
                      </FormControl>
                      {(values.typeOfReply === 'WELCOME' || values.typeOfReply === 'AWAY') && (
                        <div style={{ display: 'grid', gap: '10px', width: '100%' }}>
                          <TextField
                            name="replyMessage"
                            label="Enter Text Message"
                            value={values.replyMessage}
                            error={errors.replyMessage}
                            helperText={errors.replyMessage}
                            onChange={(e) => {
                              setFieldValue('replyMessage', e.target.value);
                            }}
                          />
                          {values.typeOfReply === 'WELCOME' ? (
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Select Your Api</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                multiple
                                name="selectApi"
                                value={values.selectApi}
                                label="Select Your Api"
                                error={errors?.selectApi}
                                onChange={(e) => setFieldValue('selectApi', e.target.value)}
                              >
                                {/* {dropdownData?.apis?.map((item) => (
                                  <MenuItem value={item}>{item}</MenuItem>
                                ))} */}
                                {webhookEndPoints?.map((item) => (
                                  <MenuItem value={item.id}>{item.name}</MenuItem>
                                ))}
                              </Select>
                              <FormHelperText error={Boolean(errors?.selectApi)}>{errors?.selectApi}</FormHelperText>
                            </FormControl>
                          ) : (
                            <>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Select Delay Type</InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  name="delayType"
                                  value={values.delayType}
                                  label="Select Delay Type"
                                  error={errors?.delayType}
                                  onChange={(e) => setFieldValue('delayType', e.target.value)}
                                >
                                  <MenuItem value="delay_timer">Send With a Delay of</MenuItem>
                                  <MenuItem value="Always send">Always send</MenuItem>
                                </Select>
                                <FormHelperText error={Boolean(errors?.delayType)}>{errors?.delayType}</FormHelperText>
                              </FormControl>
                              {values.delayType === 'delay_timer' && (
                                <TextField
                                  name="delayTime"
                                  type="number"
                                  label="In Mins"
                                  value={values.delayTime}
                                  error={errors.delayTime}
                                  helperText={errors.delayTime}
                                  onChange={(e) => {
                                    setFieldValue('delayTime', e.target.value);
                                  }}
                                />
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                    <Divider />
                    <div style={{ display: 'grid', width: '100%' }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select Recipients</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="recipients"
                          value={values.recipients}
                          label="Select Recipients"
                          error={errors?.recipients}
                          onChange={(e) => setFieldValue('recipients', e.target.value)}
                        >
                          {dropdownData?.receiptenum?.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </Select>
                        <FormHelperText error={Boolean(errors?.recipients)}>{errors?.recipients}</FormHelperText>
                      </FormControl>
                      {/* {(values.recipients === 'INCLUSION' || values.recipients === 'EXCLUSION') && (
                        <TextField
                          name="keyName"
                          label="Enter Mobile number per line"
                          value={values.keyName}
                          error={errors.keyName}
                          helperText={errors.keyName}
                          onChange={(e) => {
                            setFieldValue("keyName", (e.target.value || "").split(","))
                          }}
                        />
                      )} */}
                    </div>
                    <Divider />

                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Select Channel</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        multiple
                        name="channels"
                        value={values.channels}
                        label="Select Webhook"
                        error={errors?.channels}
                        onChange={(e) => setFieldValue('channels', e.target.value)}
                      >
                        {dropdownData?.departmentChannels?.map((item) => (
                          <MenuItem value={item?.id}>{item?.name}</MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={Boolean(errors?.channels)}>{errors?.channels}</FormHelperText>
                    </FormControl>
                  </Box>

                  <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                    <LoadingButton type="submit" variant="contained">
                      {!isEdit ? 'Create Agent' : 'Save Changes'}
                    </LoadingButton>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}
